import React from "react";
import "./footer.scss";

const ChatBotFooter = () => {
  return (
    <div className="chatbotfooter text-center d-flex align-items-center justify-content-center my-3">
      <img
        src="https://res.cloudinary.com/dyyjph6kx/image/upload/v1731588386/store/email/icon-stars.png"
        alt=""
      />
      <div>
        Need help?{" "}
        <a href="https://www.xoxoday.com/company/contact-us" target="_blank">
          Chat with us
        </a>{" "}
        for instant support!
      </div>
    </div>
  );
};

export default ChatBotFooter;
